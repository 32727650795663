import React, { useContext, useEffect, useMemo, useState } from 'react';

import { tryCatch } from '@thalesrc/js-utils';
import { useTranslation } from 'react-i18next';

import { ToasterContext } from './ToasterContext';
import { UserContext } from './UserContext';
import { MODULE_CODES_AND_PATH } from '../enums/const';
import { moduleService } from '../services/module';

export const MenuContext = React.createContext();

export function MenuProvider(props) {
  const { t } = useTranslation();

  const [menu, setMenu] = useState([]);

  const { token } = useContext(UserContext);
  const { openToaster } = useContext(ToasterContext);

  useEffect(() => {
    if (token) {
      getModules();
    }
  }, [token]);

  const getModules = async () => {
    const [err, res] = await tryCatch(moduleService.getAll());

    if (err) {
      openToaster('bottom-right', {
        severity: 'error',
        summary: t('general.error'),
        detail: t(err?.response?.data?.errors[0]),
        life: 3000
      });
      return;
    }

    createMenuStructure(res);
  };

  const getMenus = (modules, isManagement) => {
    return modules
      ?.filter((item) => item.isManagement === isManagement)
      .map((item) => {
        const module = MODULE_CODES_AND_PATH.find((mod) => mod.code === item.code);
        if (module) {
          return {
            label: t(`general.${module.key}`),
            icon: module.icon,
            to: module.to
          };
        }
        return null;
      })
      .filter((item) => item !== null);
  };

  const createMenuStructure = (modules) => {
    const mainMenu = getMenus(modules, false);
    const administrationMenu = getMenus(modules, true);

    const menuStructure = [];

    if (mainMenu?.length > 0) {
      menuStructure.push({
        label: t('general.mainMenu'),
        to: '/',
        items: mainMenu
      });
    }
    if (administrationMenu?.length > 0) {
      menuStructure.push({
        label: t('general.administrationModules'),
        to: '/',
        items: administrationMenu
      });
    }

    setMenu(menuStructure);
  };

  const value = useMemo(() => ({ menu, setMenu, getModules }), [menu, setMenu, getModules]);

  return <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>;
}
