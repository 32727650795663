import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useTranslation } from 'react-i18next';

import { LOCAL_KEYS } from '../enums/const';

export default function LanguageSelector() {
  const languages = [
    { name: 'Türkçe', languageCode: 'tr' },
    { name: 'English', languageCode: 'en' }
  ];
  const { i18n } = useTranslation();
  const languageMenu = useRef(null);

  const browserLanguage = navigator.language.split('-')[0];
  const supportedLanguages = languages.map((lang) => lang.languageCode);
  const defaultLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'tr';

  useEffect(() => {
    const storedLang = localStorage.getItem(LOCAL_KEYS.Lang);
    if (!storedLang) {
      localStorage.setItem(LOCAL_KEYS.Lang, defaultLanguage);
      i18n.changeLanguage(defaultLanguage);
    }
  }, [defaultLanguage, i18n]);

  const lang = localStorage.getItem(LOCAL_KEYS.Lang) || defaultLanguage;

  const handleChangeLang = (code) => {
    i18n.changeLanguage(code.toLowerCase());
    localStorage.setItem(LOCAL_KEYS.Lang, code.toLowerCase());
    window.location.reload();
  };

  const languageItems = languages.map((item) => ({
    label: item.name,
    template: (
      <button
        type="button"
        onClick={() => handleChangeLang(item.languageCode)}
        className={classNames('w-full p-link flex align-items-center p-2', {
          'bg-gray-200': item.languageCode === i18n.language
        })}>
        <span alt={item.name} className={`mr-2 flag flag-${item.languageCode}`} />
        <div>{item.name}</div>
      </button>
    )
  }));

  return (
    <>
      <Button
        onClick={(event) => languageMenu.current.toggle(event)}
        className="border-round-xl"
        text
        aria-label="Language">
        <span className={`flag flag-${lang}`} />
        <span className="ml-2 text-black-alpha-70">
          {languages.find((item) => item.languageCode === lang)?.languageCode.toLocaleUpperCase()}
        </span>
      </Button>
      <Menu model={languageItems} popup ref={languageMenu} id="languageMenu" />
    </>
  );
}
