import React, { useEffect, useState } from 'react';

import { addLocale, locale } from 'primereact/api';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Switch } from 'react-router-dom';

import ConditionRoute from './components/ConditionRoute';
import Loader from './components/Loader';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { DashboardContextProvider } from './context/DashboardContext';
import { LoadingContextProvider } from './context/Loading';
import { MenuProvider } from './context/MenuContext';
import { ToasterContextProvider } from './context/ToasterContext';
import { UserContextProvider } from './context/UserContext';
import { LOCAL_KEYS } from './enums/const';
import { LayoutProvider } from './layout/context/layoutcontext';
import { trLng } from './utils/locales/tr';
import pageURL from './utils/pageUrls';

const TheLayout = React.lazy(() => import('./layout/layout'));
const Login = React.lazy(() => import('./pages/Login'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const Health = React.lazy(() => import('./pages/Health'));

function App() {
  const { i18n } = useTranslation();
  const [isHydrated, setIsHydrated] = useState(false);

  const initApp = async () => {
    const local = localStorage.getItem(LOCAL_KEYS.Lang) || i18n.language;

    addLocale('tr', trLng);

    locale(local);

    localStorage.setItem(LOCAL_KEYS.Lang, local);

    await i18n.changeLanguage(local);
  };

  useEffect(() => {
    initApp();
    setIsHydrated(true);
  }, []);

  if (!isHydrated) return null;

  return (
    <LoadingContextProvider>
      <ToasterContextProvider>
        <BrowserRouter>
          <UserContextProvider>
            <DashboardContextProvider>
              <LayoutProvider>
                <MenuProvider>
                  <React.Suspense fallback={<Loader isLoaderShow />}>
                    <Switch>
                      <ConditionRoute component={Health} path={pageURL.health} exact />
                      <PublicRoute component={Login} path={pageURL.login} exact />
                      <PublicRoute component={ForgotPassword} path={pageURL.forgotPassword} exact />
                      <PublicRoute component={ResetPassword} path={pageURL.resetPassword} exact />
                      <PrivateRoute component={TheLayout} path="*" exact />
                    </Switch>
                  </React.Suspense>
                </MenuProvider>
              </LayoutProvider>
            </DashboardContextProvider>
          </UserContextProvider>
        </BrowserRouter>
      </ToasterContextProvider>
    </LoadingContextProvider>
  );
}

export default App;
