import React from 'react';

import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18nextInit';

import './styles/layout/layout.scss';
import './App.css';
import './App.scss';
import './styles/global.scss';
import './styles/flags.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
