import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { LOCAL_KEYS } from '../enums/const';

function PrivateRoute({ component: Component, ...rest }) {
  const token = localStorage.getItem(LOCAL_KEYS.Token);

  return (
    <Route
      {...rest}
      render={(props) => (token ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
}

export default PrivateRoute;
