import React, { createContext, useCallback, useMemo, useState } from 'react';

export const DashboardContext = createContext(null);

const initialState = {
  supplierIds: []
};

export function DashboardContextProvider({ children }) {
  const [dashboardState, setDashboardState] = useState(initialState);

  const setDashboardStore = useCallback((data) => {
    setDashboardState({ ...data });
  });

  const context = useMemo(
    () => ({
      dashboardState,
      setDashboardStore
    }),
    [dashboardState, setDashboardStore]
  );

  return <DashboardContext.Provider value={context}>{children}</DashboardContext.Provider>;
}
