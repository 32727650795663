import { tryCatch } from '@thalesrc/js-utils';

import http from './http';
import {
  LOGIN_URL,
  RESET_PASSWORD,
  VERIFY_CODE_URL,
  RE_SEND_CODE_URL,
  FORGOT_PASSWORD
} from '../utils/url';

export async function loginUser(payload) {
  const [error, result] = await tryCatch(http.post(LOGIN_URL, payload));

  if (error) throw error;

  return result.data.data;
}

export async function verifyCodeRequest(payload) {
  const [error, result] = await tryCatch(http.post(VERIFY_CODE_URL, payload));

  if (error) throw error;

  return result.data.data;
}

export async function reSendCodeRequest(payload) {
  const [error, result] = await tryCatch(http.post(RE_SEND_CODE_URL, payload));

  if (error) throw error;

  return result.data.data;
}

export async function forgotPassword(payload) {
  const url = FORGOT_PASSWORD;

  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function resetPassword(payload) {
  const url = RESET_PASSWORD;

  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data.data;
}
