import React from 'react';

import LanguageSelector from './LanguageSelector';

function Header() {
  return (
    <header className="app-header">
      <div className="header-content">
        <div className="language-selector">
          <LanguageSelector />
        </div>
      </div>
    </header>
  );
}

export default Header;
