import React from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99999999999;

  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999999;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100000000000;
`;

const LoadingText = styled.p`
  margin-top: 20px;
  color: #e3e3e3;
  font-size: 16px;
`;

export default function Loader({ isLoaderShow }) {
  const { t } = useTranslation();

  return (
    isLoaderShow && (
      <Overlay>
        <SpinnerContainer>
          <ProgressSpinner />
          <LoadingText>{t('general.loading')}</LoadingText>
        </SpinnerContainer>
      </Overlay>
    )
  );
}
