import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import Header from './Header';
import { LOCAL_KEYS } from '../enums/const';

function PublicRoute({ component: Component, ...rest }) {
  const token = localStorage.getItem(LOCAL_KEYS.Token);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Redirect to="/" />
        ) : (
          <>
            <Header />
            <Component {...props} />
          </>
        )
      }
    />
  );
}

export default PublicRoute;
